import { mapGetters } from "vuex";

export const profileComputed = {
  ...mapGetters("pp", [
    "profilePP",
    "ppCode",
    "pausedAt",
    "title1",
    "title2",
    "loadingPP",
    "fbPixel",
    "primaryColor",
    "profileLogo",
  ]),
};

export const authComputed = {
  ...mapGetters("auth", [
    "authUserID",
    "authStatus",
    "authFeatures",
    "authFeaturesSlugs",
    "authIsBasic",
    "authIsVideo",
    "authStatusNotPaused",
  ]),
};
