import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/state/store";
import { KcmProfile, ProfileApiError, ProfileApiResponse } from "@/types";
import helpers from "../helpers";
import cache from "../cache/cache.service";
//import store from "../../state/store";
const baseUrl = process.env.VUE_APP_P_API_ROOT
  ? process.env.VUE_APP_P_API_ROOT.replace(/\/$/, "")
  : "";

/**
 * Define a list of known errors when working with
 * the content api
 */
const Errors = {
  PersonalizationErr: "unable to load personalization profile",
};
const headers: AxiosRequestConfig = {
  auth: { username: process.env.VUE_APP_P_API_KEY ?? "", password: "" },
};
//const INDUSTRY = 1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function get(resource: string): Promise<AxiosResponse<any, any>> {
  if (window.profileAPIResp && window.profileAPIReq) {
    const profileAPIReq = helpers.decodeAPIWindowData("profileAPIReq");
    const profileAPIResp = helpers.decodeAPIWindowData("profileAPIResp");

    if (profileAPIReq.includes(resource)) {
      delete window.profileAPIResp;
      delete window.profileAPIReq;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result = { data: JSON.parse(profileAPIResp), headers: {} } as any;
      const finalResp = cache.set("profile", resource, result);
      return Promise.resolve(finalResp);
    }
  }

  const cachedResponse = cache.get("profile", resource);
  if (cachedResponse) {
    return Promise.resolve(cachedResponse);
  }

  return (
    axios
      .get(baseUrl + resource, headers)
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .then((response: AxiosResponse<any, any>) => {
        const finalResp = cache.set("profile", resource, response);
        return finalResp;
      })
  );
}

export const pService = {
  getPersonalization,
};

function getPersonalization(
  userID = "0"
): Promise<KcmProfile | ProfileApiError> {
  if (userID === "0") {
    return Promise.resolve({ error: Errors.PersonalizationErr });
  }
  store.dispatch("pp/setLoadingPP", true);
  const endpoint = "/" + userID;

  return get(endpoint)
    .then((result: ProfileApiResponse) => {
      if (result.data?.profiles) {
        result.data.profiles.updatedAt = result.data.last_update?.blog;
        return result.data.profiles;
      } else {
        return { error: "No user found matching these parameters" };
      }
    })

    .catch(() => {
      return { error: Errors.PersonalizationErr };
    })
    .finally(() => {
      store.dispatch("pp/setLoadingPP", false);
    });
}
