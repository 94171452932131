import { AuthApiResp, AuthApiResult } from "@/types";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import helpers from "../helpers";
import cache from "../cache/cache.service";
//import store from "../../state/store";
const baseUrl = process.env.VUE_APP_AUTH_API_ROOT
  ? process.env.VUE_APP_AUTH_API_ROOT.replace(/\/$/, "")
  : "";

/**
 * Define a list of known errors when working with
 * the content api
 */
const Errors = {
  NoParam: "No user found matching default parameters",
  NotFound: "No user found matching these parameters",
  AuthErr: "unable to load auth",
};
const headers: AxiosRequestConfig = {
  auth: {
    username: process.env.VUE_APP_AUTH_API_USER ?? "",
    password: process.env.VUE_APP_AUTH_API_KEY ?? "",
  },
};
//const INDUSTRY = 1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function get(resource: string): Promise<AxiosResponse<any, any>> {
  if (
    window.apiUserId &&
    window.apiUserStatus &&
    window.apiUserFeatures &&
    window.apiUserAccess &&
    window.apiUserReq
  ) {
    const apiUserReq = helpers.decodeAPIWindowData("apiUserReq");
    const apiUserId = helpers.decodeAPIWindowData("apiUserId");
    const apiUserStatus = helpers.decodeAPIWindowData("apiUserStatus");
    const apiUserFeatures = helpers.decodeAPIWindowData("apiUserFeatures");
    const apiUserAccess = helpers.decodeAPIWindowData("apiUserAccess");

    if (apiUserReq.includes(resource)) {
      delete window.apiUserReq;
      delete window.apiUserId;
      delete window.apiUserStatus;
      delete window.apiUserFeatures;
      delete window.apiUserAccess;

      const result = {
        data: {
          user: {
            id: apiUserId,
            status: apiUserStatus,
            access_levels: JSON.parse(apiUserAccess),
            features: JSON.parse(apiUserFeatures),
          },
          headers: {},
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;
      const finalResp = cache.set("auth", resource, result);
      return Promise.resolve(finalResp);
    }
  }

  const cachedResponse = cache.get("auth", resource);
  if (cachedResponse) {
    return Promise.resolve(cachedResponse);
  }

  return (
    axios
      .get(baseUrl + resource, headers)
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .then((response: AxiosResponse<any, any>) => {
        const finalResp = cache.set("auth", resource, response);
        return finalResp;
      })
  );
}

export const authService = {
  getAuth,
};

function getAuth(userID = "0"): Promise<AuthApiResult> {
  if (userID === "0") {
    return Promise.resolve({ error: Errors.NoParam });
  }
  const endpoint = "/users/" + userID + "/?expand=access_levels,features";

  return get(endpoint)
    .then((result: AuthApiResp) => {
      if (
        result.data &&
        result.data.user &&
        result.data.user.access_levels &&
        result.data.user.access_levels.filter((level) => {
          return level.industry === 1 || level.industry === 2;
        }).length > 0
      ) {
        return result.data;
      } else {
        return { error: Errors.NotFound };
      }
    })
    .catch(() => {
      return { error: Errors.AuthErr };
    });
}
